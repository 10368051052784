import React from "react";
import { useAtom } from "jotai";
import { ModelSelector } from "@/components/ModelSelector";
import { ChatWindow } from "@/components/ChatWindow";
import { selectedModelAtom } from "@/store/atoms";

export default function HomePage() {
  const [selectedModel] = useAtom(selectedModelAtom);

  return (
    <main className="flex h-screen flex-col">
      <ModelSelector />
      <ChatWindow />
    </main>
  );
}
