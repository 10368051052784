import React from "react";
import { useAtom } from "jotai";
import { selectedModelAtom } from "@/store/atoms";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

const models = [
  { id: "gpt-4", name: "GPT-4", provider: "openai" },
  { id: "claude-3", name: "Claude 3", provider: "anthropic" },
  { id: "gemini-pro", name: "Gemini Pro", provider: "gemini" },
] as const;

export function ModelSelector() {
  const [selectedModel, setSelectedModel] = useAtom(selectedModelAtom);

  return (
    <div className="p-4 border-b">
      <Select
        value={selectedModel.id}
        onValueChange={(value: string) => {
          const model = models.find((m) => m.id === value);
          if (model) setSelectedModel(model);
        }}
      >
        <SelectTrigger className="w-[200px]">
          <SelectValue placeholder="Select a model" />
        </SelectTrigger>
        <SelectContent>
          {models.map((model) => (
            <SelectItem key={model.id} value={model.id}>
              {model.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
