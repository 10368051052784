import React from "react";
import { useAtom } from "jotai";
import { chatHistoryAtom } from "@/store/atoms";
import { userAtom } from "@/store/auth";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { trpc } from "@/lib/trpc";
import { signIn } from "@/lib/auth-client";

export function ChatWindow() {
  const [chatHistory] = useAtom(chatHistoryAtom);
  const [user] = useAtom(userAtom);
  const [message, setMessage] = React.useState("");
  const sendMessage = trpc.chat.send.useMutation();

  const handleSend = async () => {
    if (!user) {
      // const { error } = await supabase.auth.signInWithOAuth({
      //   provider: "google",
      //   options: { redirectTo: window.location.origin },
      // });
      // if (error) console.error(error);
      return;
    }

    if (!message.trim()) return;

    try {
      await sendMessage.mutateAsync({
        message: message.trim(),
        modelId: "gpt-4",
      });
      setMessage("");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex-1 flex flex-col">
      {!user ? (
        <div className="flex-1 flex items-center justify-center">
          <Button onClick={() => signIn()}>Sign in with Google to start chatting</Button>
        </div>
      ) : (
        <>
          <ScrollArea className="flex-1 p-4">
            {chatHistory.map((message) => (
              <div key={message.id} className={`mb-4 ${message.role === "user" ? "text-right" : "text-left"}`}>
                <div
                  className={`inline-block p-3 rounded-lg ${message.role === "user" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
                >
                  {message.content}
                </div>
              </div>
            ))}
          </ScrollArea>
          <div className="p-4 border-t">
            <div className="flex gap-2">
              <Textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type your message..."
                className="flex-1"
              />
              <Button onClick={handleSend} disabled={sendMessage.isPending}>
                {sendMessage.isPending ? "Sending..." : "Send"}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
